<template>
  <div class="flex flex-col">
    <div class="flex-1">
      <div class="text-white bg-dark">
        <div class="container flex flex-col pr-0 mx-auto lg:pr-20 lg:flex-row">
          <div class="flex items-center mx-6 lg:w-1/2">
            <div>
              <h1 class="mt-6 mb-6 font-extrabold leading-tight lg:mb-0 text-mhead lg:text-head lg:mt-0">FAQ</h1>
              <div class="text-lg">
                See our answers to some commonly asked questions and feel free to shoot us your own through the
                <a
                  href="/contact"
                  class="underline"
                >contact form</a>.
              </div>
            </div>
          </div>

          <div class="lg:w-1/2 lg:mb-4">
            <img src="../assets/faq_desktop-01.svg" class="hidden font-bold lg:block" />
            <img src="../assets/faq_mobile-02.svg" class="font-bold lg:hidden" />
          </div>
        </div>
      </div>
      <div class="container mx-auto text-black bg-white">
        <div class="pr-4 mx-auto mb-8 lg:pr-20">
          <dl class="mt-5 lg:ml-4">
            <div v-for="(faq,index) in faqs" :key="faq.id">
              <accordion
                :header="faq.question"
                :content="faq.answer"
                :index="index"
                :isOpen="index==currOpen"
                @toggle="toggle"
              />
            </div>
          </dl>
        </div>
      </div>
    </div>
    <footer-md class="px-10 text-gray-700 lg:px-0 " />
  </div>
</template>


<script>
import Footer from "../components/Footer.vue";
import Accordion from "../components/Accordion.vue";
export default {
  components: {
    "footer-md": Footer,
    Accordion,
  },
  methods: {
    toggle(idx) {
      this.currOpen = idx == this.currOpen ? -1 : idx;
    },
  },
  mounted() {
    this.faqs = this.$parent.cms.faqs;
  },
  data() {
    return {
      faqs: [],
      currOpen: -1,
    };
  },
};
</script>

<style>
</style>
