<template>
  <div class="mt-2">
    <dt
      @click="toggle"
      class="text-lg font-bold py-2  pl-4 lg:pl-8 flex justify-between items-center cursor-pointer"
      :class="isOpen ? 'bg-dark text-white':'bg-shade text-black'"
    >
      <div v-text="header" class="flex-1 pr-6"></div>
      <div class="circle-plus" :class="isOpen ? 'opened':'closed'">
        <div class="circle">
          <div class="horizontal"></div>
          <div class="vertical"></div>
        </div>
      </div>
    </dt>
    <dd :class="isOpen ? '':'hide'" v-html="content" class="p-4 lg:px-8"></dd>
  </div>
</template>

<script>
export default {
  props: ["header", "content",'isOpen','index'],
  computed: {
    // headclass() {
    //     var class="";
    //     return class;
    // }
  },
  data() {
    return {
    //   isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.$emit('toggle',this.index);
    },
  },
};
</script>



<style scope='local' lang='scss'>

dd {
    overflow: hidden;
    transition: 0.5s ease all;
    max-height:100em;
}

.hide {
    max-height:0;
    padding-top:0 ;
    padding-bottom:0 ;
}


.closed {
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  opacity: 1;
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    background-color: white !important;
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.circle-plus {
  height: 3.5em;
  width: 4em;
  font-size: 1em;
//   opacity: 0.9;
}

.circle {
  background: transparent;
}

.circle-plus .circle {
  position: relative;
//   width: 2.55em;
//   height: 2.5em;
  // border-radius: 100%;
  // border: solid .5em #DFDAD7;
}
.circle-plus .circle .horizontal {
  position: absolute;
  background-color: black;
  width: 30px;
  height: 5px;
  left: 50%;
  margin-left: -15px;
  top: 50%;
  margin-top: -2.5px;
}
.circle-plus .circle .vertical {
  position: absolute;
  background-color: black;
  width: 5px;
  height: 30px;
  left: 50%;
  margin-left: -2.5px;
  top: 50%;
  margin-top: -15px;
}
</style>